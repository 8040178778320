<template>
  <div class="row">
    <div class="col-12 col-md-4">
      <input-text
        ref="codigoBanco"
        v-model="form.codigoBanco"
        :label="$t('FINANCEIRO.CODIGO')"
        :placeholder="$t('FINANCEIRO.CODIGO')"
        type="text"
        :maxLength="5"
        required
      />
    </div>
    <div class="col-12 col-md-8">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        :label="$t('FINANCEIRO.BANCO')"
        :placeholder="$t('FINANCEIRO.BANCO')"
        type="text"
        :maxLength="60"
        required
      />
    </div>
  </div>
</template>
<script>
import { InputText } from '@/components/inputs';
export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputText,
  },
  mounted() {
    this.$emit('refs', this.$refs);
  },
};
</script>
