import ApiService from "@/common/api/api.service"

let apiBasePath = 'Banco'

const BancosService = {
    // FUNÇÕES CRUD:
    async criar (form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form)
        return data
    },
    async editar (form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form)
        return data
    },
    async excluir (aulaId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${aulaId}`)
        return data
    },
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    async obterTodos () {
        let { data } = await ApiService.get(`${apiBasePath}`)
        return data
    }
}

export default BancosService