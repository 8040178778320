<template>
  <div>
    <titulo-bancos @cadastrar="openModal" />

    <Tabela :items="bancos" @editar="openModal" @excluir="confirmarExclusao" />

    <modal-banco
      :form="modais.bancoDados"
      :exibir="modais.banco"
      @salvar="salvar"
      @fechar="closeModal('banco')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';

// Services:
import BancoService from '@/common/services/bancos/bancos.service';

// Components:
// import Cabecalho from '@/components/headers/Cabecalho';
import Tabela from './components/Tabela';
import ModalBanco from './components/Modal';
import TituloBancos from '@/views/financeiro/estrutura/bancos/components/TituloBancos.vue';

export default {
  components: {
    TituloBancos,
    // Cabecalho,
    Tabela,
    ModalBanco,
  },
  data() {
    return {
      bancos: [],
      modais: {
        banco: false,
        bancoDados: {},
      },
    };
  },
  mounted() {
    this.getBancos();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getBancos() {
      this.$store.dispatch(START_LOADING);
      BancoService.obterTodos()
        .then(({ data }) => {
          this.bancos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    salvar(item) {
      item.id ? this.editar(item) : this.criar(item);
    },
    criar(item) {
      const codigoExistente = this.bancos.some(banco => banco.codigoBanco === item.codigoBanco);
      if (codigoExistente) {
        mensagem.erro(
          this.$t('GERAL.ERRO'), 
          "Código de banco já existente!"
        );
      }else{
        this.$store.dispatch(START_LOADING);
        BancoService.criar(item)
          .then(() => {
            mensagem.sucesso(
              this.$t('GERAL.SUCESSO'),
              this.$t('GERAL.ITEM_CRIADO').formatUnicorn({
                item: this.$t('GERAL.BANCO'),
              })
            );
            this.closeModal('banco');
            this.getBancos();
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },
    editar(item) {
      this.$store.dispatch(START_LOADING);
      BancoService.editar(item)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.ITEM_EDITADO').formatUnicorn({
              item: this.$t('GERAL.BANCO'),
            })
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('banco');
          this.getBancos();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_EXCLUSAO'),
        this.$t('GERAL.CONFIRMAR_EXCLUSAO_ITEM').formatUnicorn({
          item: item.descricao,
        })
      );
      respostaExclusao.isConfirmed ? this.excluir(item) : {};
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      BancoService.excluir(item.id)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.ITEM_DELETADO').formatUnicorn({
              item: this.$t('GERAL.BANCO'),
            })
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('banco');
          this.getBancos();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
